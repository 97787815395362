<template lang="">
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex m-3">
            <div class="flex align-items-center m-2">
              <Button
                icon="pi pi-arrow-left"
                class="p-button-rounded p-button-submit p-button-outlined"
                @click="retornar()" />
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex flex-column ml-5">
            <div class="flex">
              <div class="flex">
                <h1>{{ servico.nome }}</h1>
              </div>
            </div>
            <div class="flex flex-row flex-wrap mb-5">
              <div
                v-for="(tag, index) in servico.tags"
                :key="index"
                class="flex align-items-center m-1 justify-content-center mr-3 bg-blue-200 text-blue-700 text-base border-round font-bold text-center p-2">
                {{ tag.nome }}
              </div>
            </div>
            <div class="flex flex-column">
              <div class="flex">
                <h2>O que é</h2>
              </div>
              <div class="flex">
                <p>{{ servico.descricao }}</p>
              </div>
            </div>
            <div class="flex flex-column mt-5">
              <div class="flex">
                <h2>Quem pode utilizar o serviço</h2>
              </div>
              <div class="flex">
                <p>{{ servico.destinatario }}</p>
              </div>
            </div>
            <div class="flex flex-column mt-5">
              <div class="flex">
                <h2>Exigências</h2>
              </div>
              <div class="flex">
                <p>{{ servico.exigencia }}</p>
              </div>
            </div>
            <div class="flex flex-column mt-5">
              <div class="flex">
                <h2>Tempo para a execução do serviço</h2>
              </div>
              <div class="flex">
                <p>{{ servico.tempo }}</p>
              </div>
            </div>
            <div class="flex flex-column mt-5">
              <div class="flex flex-wrap">
                <h2>Etapas</h2>
              </div>
              <div
                v-if="servico.etapaOnline && servico.etapaOnline.length > 0"
                class="flex flex-column justify-content-start align-items-start">
                <h4>Online</h4>
                <Timeline
                  :value="servico.etapaOnline"
                  class="mt-2 mb-5 custom-timeline">
                  <template #marker="slotProps">
                    <div
                      class="flex align-items-center justify-content-center bg-blue-500 text-white text-s border-round font-bold text-center p-2">
                      {{ slotProps.item.ordem }}
                    </div> </template
                  ><template #content="slotProps">
                    <div
                      class="flex md:flex-row align-items-center text-base text-2xl text-justify"
                      style="color: gray">
                      <label style="min-width: 100px">
                        {{ slotProps.item.nome }}</label
                      >
                    </div>
                  </template>
                </Timeline>
              </div>
              <div
                v-if="servico.etapaPresencial && servico.etapaOnline.length > 0"
                class="flex flex-column justify-content-start align-items-start">
                <h4>Presencial</h4>
                <Timeline
                  :value="servico.etapaPresencial"
                  class="mb-5 custom-timeline">
                  <template #marker="slotProps">
                    <div
                      class="flex justify-content-start align-items-start bg-blue-500 text-white text-s border-round font-bold text-center p-2">
                      {{ slotProps.item.ordem }}
                    </div> </template
                  ><template #content="slotProps">
                    <div
                      class="flex md:flex-row align-items-center text-base text-2xl text-justify"
                      style="color: gray">
                      <label style="min-width: 100px">
                        {{ slotProps.item.nome }}</label
                      >
                    </div>
                  </template>
                </Timeline>
              </div>
            </div>
            <div class="flex flex-column">
              <div class="flex">
                <h2>Documentação</h2>
              </div>
              <div class="flex flex-row flex-wrap mb-5">
                <div
                  v-for="(documento, index) in servico.documentos"
                  :key="index"
                  class="flex align-items-center m-1 justify-content-center mr-3 bg-blue-200 text-blue-700 text-base border-round font-bold text-center p-2">
                  {{ documento.nome }}
                </div>
              </div>
            </div>
            <div
              v-if="servico.informacoesAdicionais != null"
              class="flex flex-column">
              <div class="flex">
                <h2>Informações Adicionais</h2>
              </div>
              <div class="flex">
                <p>{{ servico.informacoesAdicionais }}</p>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <Divider />
          <div class="flex flex-auto justify-content-end m-5">
            <Button
              v-tooltip.left="'Acessar Serviço'"
              label="Acessar Serviço"
              :placeholder="'Acessar Serviço'"
              class="p-button-lg p-button-submit"
              @click="acessarServico(servico.url)" />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import ServicoService from '@/service/ServicoService'
export default {
  props: {
    servicoId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      servico: {},
    }
  },
  created() {
    this.servicoService = new ServicoService(this.$http)
    // this.servicoId = this.$route.params.id
  },
  mounted() {
    this.carregarServico(this.servicoId)
  },

  methods: {
    carregarServico(id) {
      this.servicoService
        .getServicoById(id)
        .then((res) => {
          this.servico = res
        })
        .catch((err) => {
          this.showToastErro(err)
        })
    },

    retornar() {
      this.$options.parent.retornar()
      //this.$emit('retornarAba')
      // this.$router.back()
    },

    acessarServico(url) {
      window.open(url, '_blank')
    },

    showToastErro(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.limpar()
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style lang="scss">
.custom-timeline .p-timeline-event-opposite {
  display: none;
}
p {
  font-size: 18px;
  color: gray;
  width: 1000px; /* Aumente o valor conforme necessário */
}
</style>
