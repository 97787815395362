export default class ServicoService {
  constructor(http) {
    this._http = http
  }

  async getServicoById(id) {
    if (id != null || id != undefined) {
      const { data } = await this._http.get(`/api/servico/${id}`)
      return data
    }
  }
}
